<template>
	<section>
		<b-card class="px-table-0 mt-1 w-20-percent-cl-3 w-20-percent-cl-2">
		    <b-table small :fields="fields_documents" :items="documents" tbody-tr-class="size-14 table-row" show-empty @head-clicked="headClicked">
		    	<template #head(id)="data">
		    		<span>{{ data.label }}</span>
		    		<span :class="['cursor-pointer iconsort', {sort: data.field.key == sort.field && sort.type == 'desc'}]" style="position: relative; left: 20px;">
			        	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M3 7.99963H7.49994" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M3 3.99963H11.4999" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M3 11.9996H6.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
			        </span>
		    	</template>
		    	<template #head()="data">
		    		<span class="d-block text-center">{{ data.label }}</span>
		    	</template>
		    	<template #head(attach_file_title)="data">
		    		<span>{{ data.label }}</span>
		    	</template>	
		    	<template #cell(attach_file_title)="data">
		    		<span class="maxTwoRow">{{ data.item.attach_file_title }}</span>
		    	</template>	
		    	<template #cell(attach_content_type)="data">
					<span v-if="data.item.attach_content_type == 'null'"></span>
					<span v-else class="text-center maxThreeRow mx-auto">{{ data.item.attach_content_type }}</span>
				</template>		
		    	<template #cell(created_at)="data">
					<span class="d-block text-center">{{ data.item.created_at | formatDatebyMoment("YYYY-MM-DD", "DD/MM/YY") }}</span>
				</template>	
				<template #cell(updated_at)="data">
					<span class="d-block text-center">{{ data.item.updated_at | formatDatebyMoment("YYYY-MM-DD", "DD/MM/YY") }}</span>
				</template>	
		    	<template #cell(id)="data">					
					<span v-if="data.field.key == sort.field && sort.type == 'desc'">
						<template v-if="selected == 10">
							{{ ((total - data.index) - ((currentPage - 1) * 10 )) }}
						</template>
						<template v-else-if="selected == 20">
							{{ ((total - data.index) - ((currentPage - 1) * 20 )) }}
						</template>
						<template v-else>
							{{ ((total - data.index) - ((currentPage - 1) * 30 )) }}
						</template>
					</span>
					<span v-else>
						<template v-if="selected == 10">
							{{ ((currentPage - 1) * 10) + (data.index + 1) }}
						</template>
						<template v-else-if="selected == 20">
							{{ ((currentPage - 1) * 20) + (data.index + 1) }}
						</template>
						<template v-else>
							{{ ((currentPage - 1) * 30) + (data.index + 1) }}
						</template>
					</span>
				</template>	
				<template #cell(action)="data">
					<div class="d-block text-center">
						<span class="px-05 cursor-pointer" @click.stop="downloadDocument(data.item.attach_file_name, data.item.attach_file_title)">
							<feather-icon
					            icon="DownloadIcon"
					            size="22"
					          />
						</span>
					</div>										
				</template>		
				<template #cell()="data">
					<span class="d-block text-center">{{ data.value }}</span>
				</template>				
				<template #empty="scope">
			      	<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
			    </template>
			</b-table>
		</b-card>
		<div v-if="this.total > 0" class="paging-cus relative">
			<b-pagination
		      	v-model="currentPage"
		      	:total-rows="total"
		      	:per-page="perPage"
		      	aria-controls="active-table"
		      	first-number
	        	last-number
	        	class="just-center"
		    ></b-pagination>
		    <div class="limit-per">
				<select v-model="selected" class="cursor-pointer">
				    <option v-for="limit in limitpage">{{ limit }}</option>
				</select>			  
		    </div>
		</div>
	</section>
</template>

<script>

	import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, integer, min } from '@validations'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

	export default {
	  	components: {
	  		ValidationProvider,
          	ValidationObserver,
	  	},
	  	data() {
		    return {
	            sort: {
			    	field: 'id',
			    	type: 'asc'
			    },
			    perPage: 10,
        		currentPage: 1, 
        		total: 0,
        		limitpage: ['10', '20', '30'],
    			selected: '10',

    			fields_documents: [
                	{ key: 'id', label: 'No.' },
			        { key: 'attach_file_title', label: 'Document Name'},
			    	{ key: 'attach_content_type', label: 'Remarks'},
			    	{ key: 'created_at', label: 'Uploaded Date'},
			    	{ key: 'updated_at', label: 'Last Edit Date'},
			    	{ key: 'action', label: 'Actions'},
			    ],
		    	documents: [],	
		    	fieldsDocumentShow: {
		      		id: null,
		      		name: null,
		      		file: null,
		      		remarks: ''
		      	},
            }
		},	
		mounted () {
		    this.selected = '10'
		},
		watch: {
			currentPage(val){
				this.featctList()
			},
			selected(val){
				if(val){
					this.perPage = val
					this.featctList()
				}
			},
		},
		created(){
			this.featctList()
		},
		methods: {
			featctList(){
				this.$store
				.dispatch('auth/fetchDocumentInfo', {
					filter: {
					  	"list_search": [
						    {
						      "attachable_id": this.$route.params.id,
						      "attachable_type": "activity"
						    }
						]
					},
					limit: this.perPage,
					page: this.currentPage,
					sort: this.sort,
				})
				.then(response => {
					this.documents = response.data.data.data
					this.total = response.data.data.total
				})
				.catch((error) => {
					console.log(error)
				})
			},
			closeUpload(){
	          this.fieldAdd.file = null
	        },
	        length100(e){
	            return String(e).substring(0, 100)
	        },
	        length1000(e){
	            return String(e).substring(0, 1000)
	        },
			headClicked(key) {
				if(this.sort.field == key) {
					if(this.sort.type == 'asc') this.sort.type = 'desc'
					else this.sort.type = 'asc'
				} else {					
					this.sort.field = key
					this.sort.type = 'desc'
				}
				this.featctList()
			},

			downloadDocument(path, title){
		    	const url = `${process.env.VUE_APP_SERVER_URL}/api/downLoad?url_file=${path}`
		  		axios({
		        	method: 'get',
		        	url: url,
		        	responseType: 'blob',
		      	})
		      	.then(response => {		        
		        	this.forceFileDownload(response, title)	           
		      	})
		      	.catch(() => console.log('error occured'))
		    },
		    forceFileDownload(response, namFile){
		      	const url = window.URL.createObjectURL(new Blob([response.data]))
		      	const link = document.createElement('a')
		      	link.href = url
		      	link.setAttribute('download', namFile)
		      	document.body.appendChild(link)
		      	link.click()
		    },
		}
	};	

</script>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-flatpicker.scss';
	@import "@/assets/scss/_ite_tab_table.scss";

	.tab-pt-0 .px-table-0 > .card-body {
	    padding-top: 0;
	}
</style>