<template>
	<section id="project" class="porfolio-breadcrumb">
		<b-row style="justify-content: space-between; align-items: center" class="mb-2">
		    <b-col>
		    	<template v-if="userData && userData.isExperProject">
		    		<Breadcrumb :labels="labels" :subLabelDefault="subLabelDefault" :action_sublabel="action_sublabel" />
		    	</template>
				<template v-else>
					<Breadcrumb :labels="labels" :labelsOnlyView="labelsOnlyView" />
				</template>
		    </b-col>
		</b-row>
		<b-row>
		    <b-col md="12">
		    	<div>
			        <b-tabs card class="caption-add table-custom" v-model="tabIndex">
			            <b-tab title="Documents" class="tab-child tab-pt-0">	
							<tabDocument />
			            </b-tab>    
			            <b-tab title="Users" class="tab-child tab-pt-0">
			            	<tabUsers :projectID= "project_id" />
			            </b-tab>                        
			        </b-tabs> 
			    </div> 
		    </b-col>
		</b-row>
	</section>
</template>
<script>
	import Breadcrumb from '@/views/itees/admin-dashboard/partial/Breadcrumb'
	import tabDocument from './activity/document/List'
    import tabUsers from './activity/users/List'

	export default {
	  	components: {
			Breadcrumb,
			tabDocument,
          	tabUsers,
		},
	  	data() {
		    return {
		    	userData: JSON.parse(localStorage.getItem('userData')),
		    	labels: { "expert-our-portfolio-activity-listing-detail": "" },
		    	labelsOnlyView: { "expert-our-portfolio-activity-listing-detail": "" },

		    	subLabelDefault: {},
		    	action_sublabel: {},
		    	tabIndex: 0,
		    	project_id: null,
		    }
		},
		watch: {
			search(val) {
                this.loadDataToTab(val)                
            },  
		},	
		created(){
			this.featchItem()		

			if(this.userData && this.userData.isExperProject){
				this.subLabelDefault = { "expert-our-portfolio-activity-listing-detail": "" }
				this.action_sublabel = { "expert-our-portfolio-activity-listing-detail": "" }
			}
		},
		methods: {
			featchItem(){
	            this.$store
	            .dispatch('project/detailActivityItem', {
	              	id: this.$route.params.id
	            })
	            .then(response => {
	              	this.labels['expert-our-portfolio-activity-listing-detail'] = response.data.data.name
	              	document.title = response.data.data.name + ' | Activity Listing - ITEES | Your Global Partner of Choice'

	              	this.labelsOnlyView['expert-our-portfolio-activity-listing-detail'] = response.data.data.project_name	

	              	if(this.userData && this.userData.isExperProject){

						this.subLabelDefault['expert-our-portfolio-activity-listing-detail'] = response.data.data.project_name	

	              		this.action_sublabel['expert-our-portfolio-activity-listing-detail'] = '/expert/our-portfolio/project/'+ response.data.data.country_id + '/edit/' + response.data.data.project_id		              		
					}

	              	this.project_id = response.data.data.project_id

	              	// console.log('detail: ', response.data.data)
	            })
	            .catch((error) => {
	              	console.log(error)
					this.$toast({
                      	component: ToastificationContent,
                      	props: {
                        	title: error.response.data.message,
                        	variant: 'danger',
                      	},
                    },
                    {
                      position: 'top-center'
                    })
	            })
	        },
		}
	};	

</script>

<style lang="scss">	
	@import "@/assets/scss/_ite_tab_table.scss";
</style>

